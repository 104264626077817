.header-navbar:hover {
    background-color: #D7C333 !important;
    color: #278DAD !important;
}

.link.item:hover .menu {
    background-color: #D7C333;
}

.Menu {
    justify-content: flex-start !important;
}

    .Menu .menu {
        left: 0 !important;
    }

    .Menu .item > .menu > .item {
        background-color: #278DAD !important;
        color: white !important;
    }

        .Menu .item > .menu > .item:hover {
            background-color: #D7C333 !important;
            color: #278DAD !important;
        }
