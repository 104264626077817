.footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px; /* adjust this to your desired height */
    padding: 10px;
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: center;
}

.footer-content {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .footer-content img {
        width: 80px;
        height: 41px;
    }
